.checkoutUserInfo .logo,
.checkoutUserInfo .logout {
  display: none;
}
.checkoutUserInfo .info {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.checkoutUserInfo .content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: 1.5rem;
}
.checkoutUserInfo .displayName,
.checkoutUserInfo .availabilty {
  font-size: 18px;
  font-weight: normal;
}
.checkoutUserInfo .availabilty {
  font-size: 17px;
}