.modal-title {
  font-size: 16px;
}
.modal-body {
  padding: 20px !important;
}
.otp--modal-requestcode.justify-content-end .btn {
  background-color: transparent !important;
  color: #212529 !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  text-decoration: underline;
  font-size: 13px;
}
.otp--modal-requestcode.justify-content-end .btn.disabled {
  opacity: 0.3;
  text-decoration: none;
}
.otp--info {
  font-size: 14px;
}
.otp--modal-notify-mail {
  font-size: 13px;
  display: block;
  margin-bottom: 20px;
}
.otp--modal-prefix {
  margin-right: 10px;
  margin-left: -35px;
  font-style: italic;
  font-size: 18px;
  color: #adb5bd;
}
.otp--modal-text {
  color: #dc3545;
}
.otp--modal-code-wrapper .formError {
  color: #dc3545;
  font-size: 13px;
  display: block;
  margin-top: 8px;
  margin-bottom: 12px;
}
.otp--modal-prefix, .otp--modal-input {
  display: inline-block;
  vertical-align: middle;
}
[otp-wrap=otp--ok] {
  pointer-events: none !important;
  -webkit-filter: blur(2px) !important;
          filter: blur(2px) !important;
  opacity: 0.6 !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  -moz-user-select: none !important;
       user-select: none !important;
}