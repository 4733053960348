.checkoutTicketCompliments {
  /* questi sono i pesi indicati nelle guidelines, ma se uso questi l'interfaccia viene troppo diversa dal loro stesso documento
  $weightRegular: 400;
  $weightMedium: 500;
  $weightSemibold: 600;
  $weightBold: 700;
  */
}
.checkoutTicketCompliments .cannotUseEtc .resumePayment *,
.checkoutTicketCompliments .cannotUseEtc .vouchersTable * {
  color: #6c757d !important;
}
.checkoutTicketCompliments .cannotUseEtc .vouchersBlock {
  border-bottom: 1px solid #6c757d !important;
}
.checkoutTicketCompliments .cannotUseEtc .vouchersTable svg circle {
  fill: #6c757d !important;
}
.checkoutTicketCompliments .cannotUseEtc .vouchersTable svg path {
  fill: #adb5bd !important;
}
.checkoutTicketCompliments .vouchersTable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
}
.checkoutTicketCompliments .vouchersTable hr {
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 100%;
}
.checkoutTicketCompliments .vouchersTable .vouchersBlock {
  width: 100%;
  min-width: 100%;
}
.checkoutTicketCompliments .vouchersTable .vouchersTableRow {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.checkoutTicketCompliments .vouchersTable .vouchersTableRow > div {
  width: 33%;
  min-width: 33%;
  -webkit-box-flex: 33%;
      -ms-flex: 33%;
          flex: 33%;
}
.checkoutTicketCompliments .vouchersTable .vouchersTableRow.vouchers > div {
  padding: 8px 0;
}
.checkoutTicketCompliments .vouchersTable strong {
  font-size: 14px;
}
.checkoutTicketCompliments .vouchersTable .voucherCount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.checkoutTicketCompliments .vouchersTable .voucherCount .setVoucherIcon {
  cursor: pointer;
}
.checkoutTicketCompliments .vouchersTable .voucherCount span {
  width: 33%;
  min-width: 33%;
  -webkit-box-flex: 33%;
      -ms-flex: 33%;
          flex: 33%;
}
.checkoutTicketCompliments .etcLower {
  text-align: right;
}
.checkoutTicketCompliments .etcLower .etcAutoSelect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.checkoutTicketCompliments .etcLower .etcAutoSelect p {
  margin-bottom: 0;
}
.checkoutTicketCompliments .etcLower .custom-switch {
  margin-left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.checkoutTicketCompliments .etcLower .custom-switch label {
  margin-bottom: 0;
}
.checkoutTicketCompliments .cannotUseEtcAlert {
  text-align: left;
  margin: 1.25rem 0;
}
.checkoutTicketCompliments .cannotUseEtcAlert .danger {
  color: #C0272D;
  font-size: 14px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 120%;
}
.checkoutTicketCompliments .cannotUseEtcAlert .danger svg {
  min-width: 20px;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
  margin-right: 4px;
}
.checkoutTicketCompliments .headingRow {
  font-family: "Montserrat", sans-serif;
  color: #8C8C8C;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}
.checkoutTicketCompliments .voucherValue,
.checkoutTicketCompliments .voucherCount,
.checkoutTicketCompliments .voucherTotal,
.checkoutTicketCompliments .resumePayment .value,
.checkoutTicketCompliments .totalLabel {
  font-family: "Montserrat", sans-serif;
  color: #1D204C;
  font-weight: 500;
  font-size: 20px;
}
.checkoutTicketCompliments .setVoucherIcon svg {
  width: 20px;
  height: 20px;
}
.checkoutTicketCompliments .etcAutoSelect {
  margin-top: 1rem;
}
.checkoutTicketCompliments .etcAutoSelect p {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  line-height: 120%;
  color: #1D204C;
  font-weight: 600;
  font-size: 14px;
  padding-left: 8px;
}
.checkoutTicketCompliments .etcAutoSelect .custom-switch {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.checkoutTicketCompliments .etcAutoSelect .custom-switch input:checked ~ label {
  background: #3471DC;
}
.checkoutTicketCompliments .edenredGui {
  text-align: center;
  margin: 0 0 24px 0;
  /*
  .logoEdenred
  {
      margin:1rem auto;
      border:2px solid $etcBlue;
      border-radius:48px;
      width:100%;
      min-width:140px;
      min-height:60px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding:8px 0;
  }
  */
}
.checkoutTicketCompliments .edenredGui * {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.checkoutTicketCompliments .edenredGui .edenredPanel {
  background: #fff;
  -webkit-box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  color: #1D204C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  line-height: 120%;
  font-weight: 600;
}
.checkoutTicketCompliments .edenredGui .edenredText {
  padding-left: 16px;
}
.checkoutTicketCompliments .edenredGui .edenredText * {
  font-weight: 600;
  color: #1D204C;
}
.checkoutTicketCompliments .edenredGui .etcLogout {
  color: #C0272D;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 6px;
  display: inline-block;
  font-weight: 12px;
}
.checkoutTicketCompliments .edenredGui .edenredLogo {
  max-width: 140px;
}
.checkoutTicketCompliments .edenredGui p {
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #747474;
  font-size: 14px;
  margin-bottom: 12px;
}