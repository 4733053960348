/*
.checkoutResume
{
    .sidebarRow:last-child
    {
        margin-bottom:0 !important;
    }
    .sidebarResumeBlock
    {
        font-size: 14px;
        font-weight:400;
        &:not(:last-child)
        {
            border-bottom:1px solid rgba($black,0.25);
            margin-bottom:0.75rem;
            padding-bottom:0.75rem;
            small
            {
                margin-bottom:8px;
            }
        }

        strong
        {
            font-size: 16px;
        }
        small
        {
            display:block;
            margin-top:8px;
        }
    }
}
*/